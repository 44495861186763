export const PRODUCTION_API_URL = "https://lms.almas.education/api";
// export const PRODUCTION_API_URL = "https://manage.almas.education/api";
// export const LOCAL_API_URL = "https://manage.almas.education/api";
export const LOCAL_API_URL = "https://lms.almas.education/api";
// export const LOCAL_API_URL = "https://manage.almas.education/api";
export const API_BASE_URL = "https://lms.almas.education/api";
// export const API_BASE_URL = "https://manage.almas.education/api";
// export const API_BASE_URL = "https://manage.almas.education/api";
// export const API_BASE_URL = "http://localhost:8000/api"

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const COMMON_TITLE_STYLING = {
  textAlign: "right",
  fontSize: "50px",
  fontStyle: "normal",
  fontWeight: "800",
  lineHeight: "normal",
};
