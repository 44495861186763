import {
  Card,
  Typography,
  Avatar,
  Spin,
  Result,
  Space,
  Row,
  Col,
  Flex,
} from "antd";
import { LeftOutlined, SmileOutlined } from "@ant-design/icons";
import "./Students.scss";
import { useNavigate } from "react-router-dom";
import Home from "../home/Home";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAnswers } from "../../redux/slices/AnswersSlice"; // Adjust the import path accordingly
import { useParams } from "react-router-dom";
import DefaultPageLoader from "../../components/loaders/DefaultPageLoader";
const Students = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const answersState = useSelector((state) => state.answers);
  const exams = useSelector((state) => state.exam.exam_s);
  const navigate = useNavigate();
  const examId = id;
  useEffect(() => {
    // Call the fetchAnswers thunk with the examId
    // Replace with the actual examId
    dispatch(fetchAnswers(examId));
  }, [dispatch]);

  const { data, status, error } = answersState;

  console.log(answersState);
  let renderAnswers;
  if (data?.length > 0 || Array.isArray(data)) {
    renderAnswers = data?.map((answer) => (
      <Card
        key={answer?.id}
        style={{
          //  marginRight: 20,
          border: "none",
          borderRadius: 16,
          boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.05)",
          width: "300px",
          // height: "60px",
          cursor: "pointer",
          padding: 0,
          margin: 0,
        }}
        onClick={() => {
          navigate(`/correctAnswers/${examId}/${answer?.id}`);
        }}
      >
        <Row
          style={{ width: "100%" }}
          gutter={24}
          justifyContent={"space-around"}
          alignItems={"start"}
        >
          <Col span={6}>
            <Avatar
              src={
                answer?.users?.photo?.startsWith("http")
                  ? answer?.users?.photo
                  : `https://lms.almas.education/public/storage/${answer?.users?.photo}`
              }
              size={"large"}
            />
          </Col>
          <Col span={18}>
            <Flex justify={"space-between"} align={"flex-start"}>
              <Typography className="student-name">
                {answer?.users?.first_name} {answer?.users?.last_name}
              </Typography>
              <Typography.Text>12/3/2001</Typography.Text>
            </Flex>
            <Typography.Text style={{ fontSize: "13px" }}>
              {/* <span className="mark">{exam?.students_count}</span>/ 100 */}
              100 / <span className="mark">70</span>
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    ));
  }

  return (
    <Home>
      <Space direction={"vertical"}>
        <Typography.Title>الطلاب المجيبين على اختبار :</Typography.Title>

        {status === "loading" || status === "idle" ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DefaultPageLoader />
          </div>
        ) : data?.length === 0 ? (
          <div>
            <Result icon={<SmileOutlined />} title="لا يوجد طلاب مجيبين " />
          </div>
        ) : answersState ? (
          <div className="rightProgress">{renderAnswers}</div>
        ) : null}
      </Space>
    </Home>
  );
};
export default Students;
