import axios from "axios";
import { API_BASE_URL } from "../../../helpers/constants";

const token = localStorage.getItem("token");

// if (!token) {
//     throw new Error("Bearer token not found in localStorage");
// }

const headers = {
  Authorization: `Bearer ${token}`,
};

export const addLiveSession = async (id, body) => {
  const response = axios.post(`${API_BASE_URL}/meetings/store/${id}`, {
    ...body,
  });
  return response;
};

export const updateLiveSession = async (id, body) => {
  const response = axios.post(
    `${API_BASE_URL}/teacher/topics/${id}`,
    {
      ...body,
      _method: "PUT",
    },
    {
      headers: headers,
    }
  );
  return response;
};
