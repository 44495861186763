import { Card, Typography, Progress, Button, Result, Avatar } from "antd";
import { UserOutlined, LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import "./dashboard.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "../../components/charts/Chart";
import {
  fetchCourses,
  selectCourses,
  selectStatus,
  selectError,
} from "../../redux/slices/coursesSlice";
import { useNavigate } from "react-router-dom";
import { useCourseContext } from "../../context/CourseContext";
import Home from "../home/Home";
import DefaultPageLoader from "../../components/loaders/DefaultPageLoader";
const gridStyle = {
  width: "40%",
  // textAlign: "center",
  padding: 10,
  boxShadow: "none",
};
const conicColors = {
  "0%": "#3D5CFF",
  "50%": "#FF6905",
  "100%": "#3D5CFF00",
};
const Dashboard = () => {
  const dispatch = useDispatch();
  const courses = useSelector(selectCourses);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const navigate = useNavigate();
  // const handleViewClick = (course) => {
  //   setSelectedCourse(course);
  //   navigate(`/Video/${course.id}`);
  // };
  useEffect(() => {
    const loadCourses = async () => {
      await dispatch(fetchCourses());
    };

    loadCourses();
  }, [dispatch]);

  let rendCourse;
  if (courses.data) {
    const courseData = courses.data.courses;
    rendCourse = courseData.map((course) => (
      <Card
        key={course?.id}
        className="course-card"
        onClick={() => {
          navigate(`/course/${course.id}`);
        }}
      >
        <Typography className="course-title">{course?.title}</Typography>
        <Typography className="course-description">
          {course?.capacity} ساعة من الفيديوهات التعليمية
        </Typography>
        {/* <Typography className="courses-students">423 طالب</Typography> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 5,
          }}
        >
          <b style={{ color: "black", fontSize: 25 }}>..</b>
          {/* <Avatar.Group size={25} style={{ alignItems: "flex-end" }}>
            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=3" />
            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=4" />
          </Avatar.Group> */}
        </div>
      </Card>

      // <Card.Grid
      //   key={course.id}
      //   hoverable={false}
      //   style={{
      //     width: "100%",
      //     boxShadow: "none",
      //   }}
      // >
      //   <Card.Grid
      //     hoverable={false}
      //     style={{
      //       width: "100%",
      //       boxShadow: "none",
      //       display: "flex",
      //       backgroundColor: "#FFFFF",
      //       borderRadius: "10px",
      //       boxShadow:
      //         " 0px 22.58824px 33.88235px 0px rgba(184, 184, 210, 0.20)",
      //       gap: 20,
      //       cursor: "pointer",
      //     }}
      //   >
      //     <div
      //       style={{
      //         display: "flex",
      //         flexDirection: "column",
      //         gap: 5,
      //         alignItems: "flex-end",
      //       }}
      //       onClick={() => handleViewClick(course)}
      //     >
      //       <Typography className="courseTitle">{course.title}</Typography>

      //       <Typography className="TeacherName">
      //         <UserOutlined />
      //         {course.teacher_infos.users.first_name}{" "}
      //         {course.teacher_infos.users.last_name}
      //       </Typography>
      //       <div
      //         style={{
      //           display: "flex",
      //           flexDirection: "row-reverse",
      //           gap: 20,
      //           width: "100%",
      //         }}
      //       >
      //         <Typography className="price">${course.price}</Typography>
      //         <Typography className="details">
      //           {course.videos_count} درس
      //         </Typography>
      //         <Typography className="details">
      //           {" "}
      //           ساعة{course.capacity}
      //         </Typography>
      //         {/* <Button
      //           style={{
      //             border: "none",
      //             color: "orange",
      //             padding: 0,
      //           }}
      //           onClick={() => handleViewClick(course)}
      //         >
      //           view
      //         </Button> */}
      //       </div>
      //     </div>
      //     <img
      //       className="courseImage"
      //       src={`https://lms.karam-mustafa.com/public/storage/${course.image}`}
      //       alt=""
      //     />
      //   </Card.Grid>
      // </Card.Grid>
    ));
  }
  // if (status === "loading") {
  //   // return <Result icon={<LoadingOutlined />} title="جاري التحميل " />;
  //   // return <DefaultPageLoader />;
  // } else
  if (error === "Request failed with status code 401") {
    localStorage.removeItem("isLogedIn");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate("/");
    // return (
    //   <Result
    //     status="warning"
    //     title="There are some problems with your operation."
    //   ></Result>
    // );
  }

  return (
    <Home>
      <div className="d-title">
        {status === "loading" && <DefaultPageLoader />}
        <Typography className="s-title">ماذا سنتعلم اليوم؟ </Typography>
        {/* <div className="layers">
          <div className="layer1"></div>
          <div className="layer2"></div>
          <div className="layer3">
            <img className="image-student" src="Student.png" alt="" />
            <div className="course-info">
              <div>
                <Typography className="course-student">
                  منهاج الذكاء الاصطناعي الجديد
                </Typography>
                <Typography className="course-desc-student">
                  Learn how artificial intelligence is reshaping the way
                  marketing is done at both large and small organizations.
                </Typography>
              </div>
              <div className="Button-more">
                <Button
                  onClick={() => {
                    navigate("/courses");
                  }}
                  size="large"
                  style={{ width: "50%", borderRadius: 16 }}
                >
                  اقرأ المزيد
                </Button>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div
          style={{
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          {rendCourse}
        </div> */}
      </div>

      <div className="leftDashboard"></div>
    </Home>
  );
};
export default Dashboard;
