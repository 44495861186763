import React, { Component } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { Result, Button, Spin } from "antd";
import { Axios } from "axios";
import { apiClient } from "../../helpers/api-client";

class VideoPlayer extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  handleVimeoError = (error) => {
    console.error("Vimeo error:", error);
    this.setState({ hasError: true });
  };

  handleVideoLoad = () => {
    this.setState({ isVideoLoading: false });
  };

  handleVideoEnds = async (id) => {
    if (id != null) {
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("Bearer token not found in localStorage");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      if (localStorage.getItem("role") == 5) {
        const response = await apiClient.post(
          `student/course-watched/${id}`,
          null,
          { headers: headers }
        );
      }
    }
  };
  render() {
    const { videoUrl } = this.props;
    const { id } = this.props;
    const { hasError, isVideoLoading } = this.state;

    if (hasError) {
      return (
        <Result
          status="warning"
          title="حدث خطأ يرجى اعاد تحميل الصفحة."
          // extra={
          //   <Button type="primary" key="console">
          //     Go Console
          //   </Button>
          // }
        />
      );
    }
    return (
      <div style={{ marginLeft: 20 }}>
        {/* <Spin size="large" ></Spin> */}
        {videoUrl ? (
          <Vimeo
            video={videoUrl}
            autoplay
            className="video"
            width={750}
            height={500}
            onError={this.handleVimeoError}
            onLoaded={this.handleVideoLoad}
            loop={false}
            onPlaying={() => {
              console.log("video is playing");
            }}
            onEnd={() => {
              this.handleVideoEnds(id?.id);
            }}
          />
        ) : (
          <div className="no-video-message">
            <p>No video available.</p>
          </div>
        )}
      </div>
    );
  }
}

export default VideoPlayer;

// import { useEffect, useState } from "react";
// import axios from "axios";

// const VideoPlayer = ({ videoUrl }) => {
//   const [videoData, setVideoData] = useState(null);

//   useEffect(() => {
//     const fetchVideoData = async () => {
//       try {
//         // Replace 'YOUR_CLIENT_ID' and 'YOUR_CLIENT_SECRET' with your Vimeo app credentials
//         // const clientId = "a94686566f47f66a26a5f238c7ab1f2b2ac95a33";
//         // const clientSecret =
//         //   " JdrDyEwu/I0TjNcpStU8aAGjn7XrAGui4TRzg6CN9vUftE1BIePoLtkzxqAvHgL599evORgSpaAwxXzF3ofSs7LQQbqi31WzeLqJChGWljrTXsCXSMiOUeVg323s6p2p";

//         // // Request an access token
//         // const response = await axios.post(
//         //   "https://api.vimeo.com/oauth/authorize/client",
//         //   null,
//         //   {
//         //     auth: {
//         //       username: clientId,
//         //       password: clientSecret,
//         //     },
//         //   }
//         // );

//         // const accessToken = response.data.access_token;
//         const accessToken = "7203919ce2c8b6a96e404d1fa63da817";
//         // Use the access token to get video data
//         const videoResponse = await axios.get(
//           `https://api.vimeo.com/videos/${videoUrl}`,
//           {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//               Accept: "application/vnd.vimeo.*+json;version=3.4",
//             },
//           }
//         );

//         setVideoData(videoResponse.data);
//       } catch (error) {
//         console.error("Error fetching private video data:", error);
//       }
//     };

//     fetchVideoData();
//   }, [videoUrl]);

//   if (!videoData) {
//     return <div>Loading...</div>;
//   }
//   console.log(videoData);
//   return (
//     <div>
//       <h2>{videoData.name}</h2>
//       <iframe
//         title={videoData.name}
//         src={videoData?.player_embed_url}
//         width="640"
//         height="360"
//         frameBorder="0"
//         allowFullScreen
//       ></iframe>
//     </div>
//   );
// };

// export default VideoPlayer;
