import "../newLiveLesson/NewLiveLesson.scss";
import Home from "../home/Home";
import {
  Button,
  Form,
  Input,
  Space,
  Progress,
  Upload,
  App,
  Select,
} from "antd";
import { message, TimePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateTopic,
  uploadVideoSlice,
} from "../../redux/slices/uploadVideoSlice";
import {
  fetchSingleCourse,
  fetchTopicDetails,
} from "../../redux/slices/coursesSlice";
import DefaultPageLoader from "../../components/loaders/DefaultPageLoader";
import * as tus from "tus-js-client";
import PageTitle from "../../components/Title/PageTitle";

dayjs.extend(customParseFormat);

const AddNewLesson = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notification } = App.useApp();
  const [formData, setFormData] = useState({
    title: "",
    Description: "",
    length: "",
    order: 0,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query] = useSearchParams();
  const { id } = useParams();
  const [topicDetails, setTopicDetails] = useState();
  const [progressValue, setProgressValue] = useState("0");
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState();
  const [orders, setOrdersList] = useState({});

  const validateForm = () => {
    const { title, Description, order, length } = formData;
    if (title && Description && order && length) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const publishOrdersSelect = (count) => {
    const list = [];
    for (let index = 1; index <= count; index++) {
      list.push({ label: `${index}`, value: index });
    }
    setOrdersList(list);
  };

  const fetchData = async () => {
    if (query.size > 0) {
      if (query.get("lesson") != null) {
        const response = await dispatch(fetchTopicDetails(query.get("lesson")));
        setTopicDetails(response.payload);
        publishOrdersSelect(response.payload.count);
      }
    } else {
      const response = await dispatch(fetchSingleCourse(id));
      publishOrdersSelect(response.payload.count);
      setTopicDetails({});
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    validateForm();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    const timeOnly = values.length
      ? dayjs(values.length, "HH:mm:ss").format("HH:mm:ss")
      : null;
    let topicId = 0;
    if (query.size > 0) {
      topicId = query.get("lesson");
    }
    const fileSubmitted = typeof file !== "undefined" ? true : false;
    let data = {
      title: values.title,
      description: values.description,
      order: values.order,
      length: timeOnly,
      id: topicId,
    };

    if (fileSubmitted == true) {
      data = { ...data, size: file?.size };
    }

    if (query.size > 0) {
      data = { ...data, _method: "PUT" };
      if (topicId != null) {
        await dispatch(updateTopic(data))
          .unwrap()
          .then((response) => {
            const upload = new tus.Upload(file, {
              uploadUrl: response.object_upload.upload_link,
              retryDelays: [0, 3000, 5000, 10000, 20000],
              metadata: {
                filename: file?.name,
              },
              onError: function (error) {
                notification.error({
                  message: "حدث خطأ ما أثناء الرفع, الرجاء المحاولة لاحقا",
                });
              },
              onProgress: function (bytesUploaded, bytesTotal) {
                let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(
                  2
                );
                setProgressValue(percentage);
              },
              onSuccess: function () {
                notification.success({ message: "تم رفع الدرس بنجاح" });
                navigate(-1);
              },
            });

            if (fileSubmitted == true) {
              upload.start();
            } else {
              notification.success({ message: "تم رفع الدرس بنجاح" });
              navigate(-1);
            }
          })
          .catch((error) => {
            message.error("حدث خطأ يرجى اعادة المحاولة");
          });
      }
    } else {
      const response = await dispatch(uploadVideoSlice({ data, id }));
      const upload = new tus.Upload(file, {
        uploadUrl: response.payload.object_upload.upload_link,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file?.name,
        },
        onError: function (error) {
          notification.error({
            message: "حدث خطأ ما أثناء الرفع, الرجاء المحاولة لاحقا",
          });
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          setProgressValue(percentage);
        },
        onSuccess: function () {
          notification.success({ message: "تم رفع الدرس بنجاح" });
          navigate(-1);
        },
      });

      if (fileSubmitted == true) {
        upload.start();
      } else {
        notification.success({ message: "تم رفع الدرس بنجاح" });
        navigate(-1);
      }
    }

    setLoading(false);
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      setFile(file);
      return false;
    },
    fileList,
  };

  const isProgressing = progressValue != "0" ? true : false;
  return (
    <Home>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 0,
          width: "100%",
        }}
      >
        {typeof topicDetails == "undefined" ? (
          <DefaultPageLoader />
        ) : (
          <>
            <PageTitle>{query.size > 0 ? "تعديل درس" : "درس جديد"}</PageTitle>
            <Form
              key={"topic_form"}
              style={{ width: "80%" }}
              onFinish={handleSubmit}
              layout="vertical"
              initialValues={
                query.size > 0
                  ? {
                      title: topicDetails?.Video.title,
                      description: topicDetails?.Video.description,
                      order: topicDetails?.topic.order,
                      length: dayjs(topicDetails?.Video.length, "HH:mm:ss"),
                    }
                  : {}
              }
            >
              <div style={{ marginTop: 0 }}>
                <Form.Item
                  label="اسم الدرس"
                  name="title"
                  rules={[
                    { required: true, message: "اسم الدرس مطلوب" },
                    { min: 0, max: 255 },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="الوصف"
                  name={"description"}
                  rules={[
                    { required: true, message: "وصف الدرس مطلوب" },
                    { min: 0, max: 255 },
                  ]}
                >
                  <Input size="large" placeholder=" اكتب الوصف" />
                </Form.Item>

                <Form.Item label="الترتيب" name={"order"}>
                  <Select options={orders} />
                </Form.Item>
                {/* <Form.Item
                  label="الوقت"
                  name={"length"}
                  rules={[{ required: true, message: "مدة الدرس مطلوب" }]}
                >
                  <TimePicker style={{ width: "100%" }} size="large" />
                </Form.Item> */}
                <Form.Item
                  label="الدرس"
                  name={"video_file"}
                  rules={[
                    {
                      required: query.size > 0 ? false : true,
                      message: "الفيديو مطلوب",
                    },
                  ]}
                >
                  <Upload {...props} name="file_data">
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                  {isProgressing && <Progress percent={progressValue} />}
                </Form.Item>
                <Space size="small">
                  <Button
                    disabled={isProgressing}
                    size="large"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    إلغاء
                  </Button>
                  <Button
                    disabled={isProgressing}
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                  >
                    حفظ
                  </Button>
                </Space>
              </div>
            </Form>
          </>
        )}
      </div>
    </Home>
  );
};
export default AddNewLesson;
