import React from "react";
import { Avatar } from "antd";
import {
  ExamIcon,
  PauseIcon,
  PlayIcon,
  TopicWatchedIcon,
} from "../icons/Icons";
import { UserOutlined } from "@ant-design/icons";

export default function TopicIcons({ selectedVideo, topic }) {
  let icon = null;
  if (selectedVideo?.id == topic?.id) {
    icon = <PauseIcon />;
  } else if (topic.exam_id) {
    icon = <ExamIcon />;
  } else if (topic.video_id) {
    icon = <PlayIcon />;
  } else if (topic.is_completed) {
    icon = <TopicWatchedIcon />;
  } else {
    icon = <UserOutlined />;
  }
  return <Avatar size={"large"} icon={icon} />;
}
