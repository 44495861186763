import { Card, Typography, Result, Spin, Space } from "antd";
import { SmileOutlined, LoadingOutlined } from "@ant-design/icons";
import "./Tests.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTeacherExams } from "../../redux/slices/Questions";
import { useNavigate } from "react-router-dom";
import Home from "../home/Home";
import PageTitle from "../../components/Title/PageTitle";
import DefaultPageLoader from "../../components/loaders/DefaultPageLoader";

const Tests = () => {
  const dispatch = useDispatch();
  const exams = useSelector((state) => state.exam.exam_s);
  const status = useSelector((state) => state.exam.status);
  const navigate = useNavigate();
  let renderExams;

  useEffect(() => {
    if (localStorage.getItem("role") == "4" && exams) {
      const loadExams = async () => {
        await dispatch(fetchTeacherExams());
      };

      loadExams();
    } else if (exams === null) {
      // No lessons available, display Result component
      return <Result icon={<SmileOutlined />} title="No lessons available" />;
    }
  }, [dispatch]);

  console.log(exams);

  if (localStorage.getItem("role") == "4" && exams) {
    renderExams = exams?.map((exam) => (
      <div className="cards-students">
        <Card
          key={exam?.id}
          className="test-card"
          onClick={() => {
            navigate(`/students/${exam?.id}`);
          }}
        >
          <Space size={"small"} direction={"vertical"}>
            <Typography className="course-names">{exam?.title}</Typography>
            <Typography className="number-students">
              {/* <span className="mark">{exam?.students_count}</span> طالب متقدم{" "}
              {exam?.courses?.students_count}/ */}
              {exam?.courses?.students_count} /
              <span className="mark">{exam?.students_count}</span> طالب متقدم
            </Typography>
            <Typography className="date"> {exam?.start_date}</Typography>
          </Space>
        </Card>
      </div>
    ));
  } else {
    return <Result icon={<LoadingOutlined />} title="Loading..." />;
  }

  return (
    <Home>
      <div className="full-tests">
        <div className="courses-infos" style={{ flex: 5 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <PageTitle>الاختبارات</PageTitle>
          </div>

          {status === "loading" || status === "idle" ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DefaultPageLoader />
            </div>
          ) : exams?.length === 0 ? (
            <Result icon={<SmileOutlined />} title="لا يوجد اختبارات" />
          ) : exams ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {renderExams}
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            backgroundColor: "#DDEDFF80",
            flexDirection: "column",
            flex: 1.5,
            justifyContent: "flex-start",
            padding: 20,
            height: "100%",
            gap: 10,
            width: "100%",
          }}
        >
          <div style={{ padding: 20, display: "none" }}>
            <Typography className="not-corrected">لم تصحح بعد</Typography>
          </div>
        </div>
      </div>
    </Home>
  );
};
export default Tests;
