import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Typography,
  DatePicker,
  message,
  Select,
  Checkbox,
  Image,
  Modal,
  Upload,
  message as antdMessage,
  Result,
} from "antd";
import {
  MinusCircleOutlined,
  EditFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import Home from "../home/Home";
import moment from "moment";

import {
  fetchTeacherExamById,
  deleteTeacherExam,
  updateTeacherExam,
} from "../../redux/slices/Questions";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/Title/PageTitle";
import DefaultPageLoader from "../../components/loaders/DefaultPageLoader";
const { RangePicker } = DatePicker;
//const [loading, setLoading] = useState(false);
const EditExam = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [totalMarks, setTotalMarks] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [question_title, setQuestionTitle] = useState("");
  const [question_description, setQDescription] = useState("");
  const [question_marks, setMark] = useState(null);
  const [type, setQuestionType] = useState("text");
  const [options, setOptions] = useState(["", "", ""]);
  const [optionsCorrect, setOptionsCorrect] = useState([false, false, false]);
  const [questionData, setQuestionData] = useState([]);
  const [courseId, setCourseId] = useState("");

  const getFileExtension = (url) => {
    if (url) {
      const parts = url.split(".");
      return parts[parts.length - 1].toLowerCase();
    }
    return "";
  };

  const { status, error } = useSelector((state) => state.exam);

  const [showFileUpload, setShowFileUpload] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleNextQuestion = () => {
    if (currentQuestionIndex < exam?.exam?.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };
  useEffect(() => {
    dispatch(fetchTeacherExamById(id));
  }, [dispatch, id]);

  const exam = useSelector((state) => state.exam.exam);
  useEffect(() => {
    if (exam) {
      setTitle(exam?.exam?.title);
      setDescription(exam?.exam?.description);
      setTotalMarks(exam?.exam?.total_marks);
      setEndTime(exam?.exam?.end_date);
      setStartTime(exam?.exam?.start_date);
      setCourseId(exam?.exam?.course_id);
      const initialQuestionData = exam?.exam?.questions.map((question) => {
        const commonProps = {
          id: question.id,
          title: question.title,
          description: question.description,
          marks: question.marks,
          type: question.type,
        };

        if (question.type === "options") {
          return {
            ...commonProps,
            options: question.options || ["", "", ""],
            optionsCorrect: question.is_correct || [false, false, false],
          };
        } else if (question.type === "file") {
          return {
            ...commonProps,
            file: question.file || null,
          };
        } else if (question.type === "text") {
          return commonProps;
        }
        return commonProps;
      });

      setQuestionData(initialQuestionData);
    }
  }, [exam]);
  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
  };
  const showModel = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);

  const [showEditForm, setShowEditForm] = useState(true);

  const handleEditClick = () => {
    setShowEditForm(false);
    setShowFileUpload(!showFileUpload);
  };
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const examData = {
    title: title,
    description: description,
    total_marks: totalMarks,
    start_date: startTime,
    end_date: endTime,
    questions: questionData,
    course_id: courseId,
  };
  const saveUpdateBtn = async () => {
    try {
      message.info("جاري تعديل الاختبار");

      // Assuming `id` and `examData` are defined somewhere in your component.
      await dispatch(updateTeacherExam({ id, examData }));

      // Do any other logic you need after the dispatch is successful.
    } catch (error) {
      // Handle any errors that might occur during the dispatch.
      console.error("Error updating teacher exam:", error);
      message.error("حدث خطأ");
    } finally {
      message.success("تم تعديل الاختبار");
      navigate(-1);
    }
  };

  console.log(status);
  return (
    <Home>
      <div className="ExamContainer">
        {status === "loading" ? (
          <div style={{ width: "58vw" }}>
            <DefaultPageLoader />
          </div>
        ) : showEditForm ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 0,
              height: "100%",
              flex: 10,
            }}
          >
            <PageTitle>تعديل الاختبار</PageTitle>

            <Form>
              <div style={{ marginTop: 0, marginLeft: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    paddingBottom: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className="question-numbers">
                    معلومات الاختبار
                  </Typography>

                  <DeleteOutlined
                    style={{
                      fontSize: 25,
                      color: "orange",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      showModel();
                    }}
                  />
                  <Modal
                    width={"35%"}
                    open={open}
                    title={
                      <Typography style={{ textAlign: "center", fontSize: 40 }}>
                        هل أنت متأكد؟
                      </Typography>
                    }
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={(_, {}) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          size="large"
                          onClick={handleOk}
                          style={{
                            backgroundColor: "#fff",
                            color: "#11142D",
                            border: "1px solid #11142D",
                            width: "25%",
                            borderRadius: 16,
                          }}
                        >
                          الغاء
                        </Button>
                        <Button
                          size="large"
                          onClick={() => {
                            handleOk();
                            dispatch(deleteTeacherExam(id));
                            message.success("تم حذف الامتحان بالكامل");
                            navigate(-1);
                          }}
                          style={{
                            backgroundColor: "#11142D",
                            color: "#fff",
                            width: "25%",
                            borderRadius: 16,
                          }}
                        >
                          تأكيد
                        </Button>
                      </div>
                    )}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "#11142D",
                        fontSize: "16px",
                      }}
                    >
                      هل انت متأكد من حذف الامتحان لايمكن استرجاعه بعد ذلك!
                    </p>
                  </Modal>
                </div>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      gap: 20,
                    }}
                  >
                    <Typography className="related-title">العنوان</Typography>
                    <Input
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="اكتب العنوان"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </Form.Item>

                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      gap: 20,
                    }}
                  >
                    <Typography className="related-title">شرح </Typography>
                    <Input
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="شرح الاختبار"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </Form.Item>
                {/* <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      width: "100%",
                      justifyContent: "space-between",
                      gap: 20,
                    }}
                  >
                    <DatePicker.RangePicker
                      showTime
                      size="large"
                      style={{ width: "100%" }}
                      value={[startTime, endTime]}
                      onChange={(dates) => {
                        if (
                          dates &&
                          dates.length === 2 &&
                          (!startTime.isSame(dates[0]) ||
                            !endTime.isSame(dates[1]))
                        ) {
                          setStartTime(dates[0]);
                          setEndTime(dates[1]);
                        }
                      }}
                    />

                    <Typography className="related-title">
                      الوقت البداية والنهاية
                    </Typography>
                  </div>
                </Form.Item> */}
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "50%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography className="related-title">
                      {" "}
                      العلامة كاملة
                    </Typography>
                    <Input
                      type="number"
                      size="large"
                      style={{ width: "66%" }}
                      placeholder="علامة كاملة للاختبار"
                      value={totalMarks}
                      onChange={(e) => setTotalMarks(e.target.value)}
                    />
                  </div>
                </Form.Item>
              </div>
              <div
                style={{ marginRight: 40, marginTop: 0, marginLeft: 20 }}
              ></div>

              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="text"
                    icon={
                      <EditFilled style={{ fontSize: 20, color: "#2F3CED" }} />
                    }
                    onClick={handleEditClick}
                    size="small"
                    style={{ border: "none" }}
                  ></Button>
                  <Typography className="related-title">
                    تعديل الاختبار{" "}
                  </Typography>
                </div>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div
            style={{
              marginRight: 40,
              marginTop: 0,
              marginLeft: 20,
              // backgroundColor: "green",
              //    display: "flex",
              flexDirection: "row",
              flex: 7,
            }}
          >
            {exam?.exam?.questions.map(
              (question, index) =>
                index === currentQuestionIndex && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        paddingBottom: 20,
                      }}
                      key={index}
                    >
                      <Typography
                        className="question-numbers"
                        style={{ fontSize: 40 }}
                      >
                        {`معلومات السؤال  #${index + 1} `}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        //  backgroundColor: "red",
                      }}
                    >
                      <Form.Item>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                            gap: 20,
                          }}
                        >
                          <Typography className="related-title">
                            العنوان
                          </Typography>
                          <Input
                            size="large"
                            style={{ width: "100%" }}
                            placeholder="اكتب العنوان"
                            value={questionData[currentQuestionIndex].title}
                            onChange={(e) => {
                              const updatedQuestionData = [...questionData];
                              updatedQuestionData[currentQuestionIndex].title =
                                e.target.value;
                              setQuestionData(updatedQuestionData);
                            }}
                          />
                        </div>
                      </Form.Item>

                      <Form.Item>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                            gap: 20,
                          }}
                        >
                          <Typography className="related-title">
                            السؤال{" "}
                          </Typography>
                          <Input
                            size="large"
                            style={{ width: "100%" }}
                            placeholder="اكتب السؤال"
                            value={
                              questionData[currentQuestionIndex].description
                            }
                            onChange={(e) => {
                              const updatedQuestionData = [...questionData];
                              updatedQuestionData[
                                currentQuestionIndex
                              ].description = e.target.value;
                              setQuestionData(updatedQuestionData);
                            }}
                          />
                        </div>
                      </Form.Item>
                    </div>
                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "50%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography className="related-title">
                          {" "}
                          العلامة السؤال
                        </Typography>
                        <Input
                          type="number"
                          size="large"
                          style={{ width: "66%" }}
                          placeholder="علامة  السؤال"
                          value={questionData[currentQuestionIndex].marks}
                          onChange={(e) => {
                            const updatedQuestionData = [...questionData];
                            updatedQuestionData[currentQuestionIndex].marks =
                              e.target.value;
                            setQuestionData(updatedQuestionData);
                          }}
                        />
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          width: "50%",
                          justifyContent: "space-between",
                          gap: 20,
                        }}
                      >
                        <Select
                          size="large"
                          style={{ width: "66%" }}
                          value={question.type}
                          disabled
                        >
                          <Select.Option value="text">نص</Select.Option>
                          <Select.Option value="file">ملف</Select.Option>
                          <Select.Option value="options">اختياري</Select.Option>
                        </Select>
                        <Typography className="related-title">
                          نوع السؤال
                        </Typography>
                      </div>
                    </Form.Item>
                    {questionData[currentQuestionIndex].type === "options" && (
                      <Form.Item>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",

                            gap: 40,
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography className="related-title">
                            اختياري
                          </Typography>
                          {questionData[currentQuestionIndex].options.map(
                            (option, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 8,
                                }}
                              >
                                <Input
                                  size="large"
                                  style={{ width: "calc(100% - 32px)" }}
                                  placeholder={`اكتب الاختيار ${index + 1}`}
                                  value={option?.title}
                                  // ...
                                  onChange={(e) => {
                                    setQuestionData((prevQuestionData) => {
                                      const updatedQuestionData = [
                                        ...prevQuestionData,
                                      ];
                                      const currentQuestion = {
                                        ...updatedQuestionData[
                                          currentQuestionIndex
                                        ],
                                      };

                                      currentQuestion.options = [
                                        ...currentQuestion.options.slice(
                                          0,
                                          index
                                        ),
                                        { title: e.target.value }, // Assuming options is an array of objects with a title field
                                        ...currentQuestion.options.slice(
                                          index + 1
                                        ),
                                      ];

                                      updatedQuestionData[
                                        currentQuestionIndex
                                      ] = currentQuestion;
                                      return updatedQuestionData;
                                    });
                                  }}
                                  // ...
                                />

                                <Checkbox
                                  checked={
                                    questionData[currentQuestionIndex]
                                      .optionsCorrect[index]
                                  }
                                  onChange={(e) => {
                                    const updatedQuestionData = [
                                      ...questionData,
                                    ];
                                    updatedQuestionData[
                                      currentQuestionIndex
                                    ].optionsCorrect[index] = e.target.checked
                                      ? 1
                                      : 0;
                                    setQuestionData(updatedQuestionData);
                                  }}
                                />
                                <Button
                                  type="text"
                                  icon={
                                    <MinusCircleOutlined
                                      style={{
                                        fontSize: 20,
                                        color: "#FF6905",
                                      }}
                                    />
                                  }
                                  size="small"
                                  style={{ border: "none" }}
                                  onClick={() => {
                                    setQuestionData((prevQuestionData) => {
                                      const updatedQuestionData = [
                                        ...prevQuestionData,
                                      ];
                                      const currentQuestion = {
                                        ...updatedQuestionData[
                                          currentQuestionIndex
                                        ],
                                      };

                                      currentQuestion.options.splice(index, 1);
                                      currentQuestion.optionsCorrect.splice(
                                        index,
                                        1
                                      );

                                      updatedQuestionData[
                                        currentQuestionIndex
                                      ] = currentQuestion;
                                      return updatedQuestionData;
                                    });
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </Form.Item>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: 20,
                        gap: 30,
                      }}
                    >
                      {currentQuestionIndex ===
                        exam?.exam?.questions.length - 1 && (
                        <Button
                          onClick={() => {
                            saveUpdateBtn();
                          }}
                          size="large"
                          style={{
                            backgroundColor: "rgb(17, 20, 45)",
                            border: "1px solid rgb(163, 163, 165)",
                            borderRadius: 16,

                            color: " rgb(255, 255, 255)",
                          }}
                        >
                          تعديل
                        </Button>
                      )}
                      {currentQuestionIndex !==
                        exam?.exam?.questions.length - 1 && (
                        <Button
                          size="large"
                          style={{
                            backgroundColor: "rgb(17, 20, 45)",
                            border: "1px solid rgb(163, 163, 165)",
                            borderRadius: 16,

                            color: " rgb(255, 255, 255)",
                          }}
                          onClick={handleNextQuestion}
                          disabled={
                            currentQuestionIndex ===
                            exam?.exam?.questions.length - 1
                          }
                        >
                          التالي
                        </Button>
                      )}
                      <Button
                        size="large"
                        style={{
                          //backgroundColor: "rgb(17, 20, 45)",
                          border: "1px solid rgb(163, 163, 165)",
                          borderRadius: 16,

                          //  color: " rgb(255, 255, 255)",
                        }}
                        onClick={handlePreviousQuestion}
                        disabled={currentQuestionIndex === 0}
                      >
                        السابق
                      </Button>
                    </div>
                  </div>
                )
            )}
          </div>
        )}

        <div
          style={{
            backgroundColor: "#DDEDFF80",
            display: "flex",
            flex: "5",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          {exam?.exam?.questions.map((question, index) => (
            <div key={index}>
              {question.type === "file" && index === currentQuestionIndex && (
                <div>
                  {(() => {
                    const fileExtension = getFileExtension(question?.file);
                    if (
                      fileExtension === "jpg" ||
                      fileExtension === "jpeg" ||
                      fileExtension === "png"
                    ) {
                      return (
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {showFileUpload && (
                            <Image
                              src={questionData[currentQuestionIndex].file}
                              alt=""
                              style={{ padding: 20, borderRadius: 16 }}
                            />
                          )}
                          {/* Upload button for image files */}
                          {showFileUpload && (
                            <Upload name="file" showUploadList={false}>
                              {/* <Button icon={<UploadOutlined />}>
                                Upload Image
                              </Button> */}
                            </Upload>
                          )}
                        </div>
                      );
                    } else if (fileExtension === "pdf") {
                      return (
                        <>
                          {showFileUpload && (
                            <embed
                              src={questionData[currentQuestionIndex].file}
                              type="application/pdf"
                              width="100%"
                              height="500px"
                            />
                          )}
                          {/* Upload button for PDF files */}
                          {showFileUpload && (
                            <Upload name="file" showUploadList={false}>
                              {/* <Button icon={<UploadOutlined />}>
                                Upload PDF
                              </Button> */}
                            </Upload>
                          )}
                        </>
                      );
                    } else {
                      return;
                    }
                  })()}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Home>
  );
};

export default EditExam;
