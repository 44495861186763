import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Typography,
  DatePicker,
  message,
  Select,
  Checkbox,
  Flex,
} from "antd";
import { MinusCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  fetchTeacherExamById,
  updateTeacherExam,
} from "../../redux/slices/Questions";
import PageTitle from "../../components/Title/PageTitle";
import DefaultPageLoader from "../../components/loaders/DefaultPageLoader";
const EditExamForm = ({ id, setShowEditExamForm, onCancel }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [totalMarks, setTotalMarks] = useState(null);
  const dispatch = useDispatch();
  const [questionData, setQuestionData] = useState([]);
  const [courseId, setCourseId] = useState("");

  const { status, error } = useSelector((state) => state.exam);
  const exam = useSelector((state) => state.exam.exam);

  const [showFileUpload, setShowFileUpload] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleNextQuestion = () => {
    if (currentQuestionIndex < exam?.exam?.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  useEffect(() => {
    dispatch(fetchTeacherExamById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (exam) {
      setTitle(exam?.exam?.title);
      setDescription(exam?.exam?.description);
      setTotalMarks(exam?.exam?.total_marks);
      setEndTime(exam?.exam?.end_date);
      setStartTime(exam?.exam?.start_date);
      setCourseId(exam?.exam?.course_id);
      const initialQuestionData = exam?.exam?.questions.map((question) => {
        const commonProps = {
          id: question.id,
          title: question.title,
          description: question.description,
          marks: question.marks,
          type: question.type,
        };

        if (question.type === "options") {
          return {
            ...commonProps,
            options: question.options || ["", "", ""],
            optionsCorrect: question.is_correct || [false, false, false],
          };
        } else if (question.type === "file") {
          return {
            ...commonProps,
            file: question.file || null,
          };
        } else if (question.type === "text") {
          return commonProps;
        }
        return commonProps;
      });

      setQuestionData(initialQuestionData);
    }
  }, [exam]);
  const formatDateTime = (dateTime) => {
    return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
  };

  const [showEditForm, setShowEditForm] = useState(true);

  const handleEditClick = () => {
    setShowEditForm(false);
    setShowFileUpload(!showFileUpload);
  };
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const examData = {
    title: title,
    description: description,
    total_marks: totalMarks,
    start_date: startTime,
    end_date: endTime,
    questions: questionData,
    course_id: courseId,
  };
  const saveUpdateBtn = async () => {
    try {
      message.info("جاري تعديل الاختبار");

      // Assuming `id` and `examData` are defined somewhere in your component.
      await dispatch(updateTeacherExam({ id, examData }));

      // Do any other logic you need after the dispatch is successful.
    } catch (error) {
      // Handle any errors that might occur during the dispatch.
      console.error("Error updating teacher exam:", error);
      message.error("حدث خطأ");
    } finally {
      message.success("تم تعديل الاختبار");
      setShowEditExamForm(false);
    }
  };

  return (
    <>
      <div className="ExamContainer">
        {status === "loading" ? (
          <div style={{ width: "58vw" }}>
            <DefaultPageLoader />
          </div>
        ) : showEditForm ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 0,
              height: "100%",
              flex: 10,
            }}
          >
            <Flex justify="space-between" align="center">
              <PageTitle>تعديل الاختبار</PageTitle>
              <Button type={"text"} onClick={() => setShowEditExamForm(false)}>
                <ArrowLeftOutlined />
              </Button>
            </Flex>

            <Form layout="vertical">
              <div style={{ marginTop: 0, marginLeft: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    paddingBottom: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className="question-numbers">
                    معلومات الاختبار
                  </Typography>
                </div>
                <Form.Item label={"العنوان"}>
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="اكتب العنوان"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Item>

                <Form.Item label={"شرح"}>
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="شرح الاختبار"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label={"العلامة كاملة"}>
                  <Input
                    type="number"
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="علامة كاملة للاختبار"
                    value={totalMarks}
                    onChange={(e) => setTotalMarks(e.target.value)}
                  />
                </Form.Item>
              </div>
              <div
                style={{ marginRight: 40, marginTop: 0, marginLeft: 20 }}
              ></div>

              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleEditClick}
                  style={{
                    backgroundColor: "rgb(17, 20, 45)",
                    border: "1px solid rgb(163, 163, 165)",
                    borderRadius: 16,

                    color: " rgb(255, 255, 255)",
                  }}
                >
                  تعديل الاختبار
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div
            style={{
              marginRight: 40,
              marginTop: 0,
              marginLeft: 20,
              flexDirection: "row",
              flex: 7,
            }}
          >
            <Form layout="vertical">
              {exam?.exam?.questions.map(
                (question, index) =>
                  index === currentQuestionIndex && (
                    <div>
                      <Flex justify="space-between" align="center">
                        <PageTitle>{`معلومات السؤال  #${
                          index + 1
                        } `}</PageTitle>
                        <Button
                          type={"text"}
                          onClick={() => setShowEditExamForm(false)}
                        >
                          <ArrowLeftOutlined />
                        </Button>
                      </Flex>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Form.Item label={"العنوان"}>
                          <Input
                            size="large"
                            style={{ width: "100%" }}
                            placeholder="اكتب العنوان"
                            value={questionData[currentQuestionIndex].title}
                            onChange={(e) => {
                              const updatedQuestionData = [...questionData];
                              updatedQuestionData[currentQuestionIndex].title =
                                e.target.value;
                              setQuestionData(updatedQuestionData);
                            }}
                          />
                        </Form.Item>

                        <Form.Item label={"السؤال"}>
                          <Input
                            size="large"
                            style={{ width: "100%" }}
                            placeholder="اكتب السؤال"
                            value={
                              questionData[currentQuestionIndex].description
                            }
                            onChange={(e) => {
                              const updatedQuestionData = [...questionData];
                              updatedQuestionData[
                                currentQuestionIndex
                              ].description = e.target.value;
                              setQuestionData(updatedQuestionData);
                            }}
                          />
                        </Form.Item>
                      </div>
                      <Form.Item label={"علامة السؤال"}>
                        <Input
                          type="number"
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="علامة  السؤال"
                          value={questionData[currentQuestionIndex].marks}
                          onChange={(e) => {
                            const updatedQuestionData = [...questionData];
                            updatedQuestionData[currentQuestionIndex].marks =
                              e.target.value;
                            setQuestionData(updatedQuestionData);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={"نوع السؤال"}>
                        <Select size="large" value={question.type} disabled>
                          <Select.Option value="text">نص</Select.Option>
                          <Select.Option value="file">ملف</Select.Option>
                          <Select.Option value="options">اختياري</Select.Option>
                        </Select>
                      </Form.Item>
                      {questionData[currentQuestionIndex].type ===
                        "options" && (
                        <Form.Item label={"اختياري"}>
                          <Flex
                            wrap="wrap"
                            justifyContent={"space-between"}
                            gap={12}
                          >
                            {questionData[currentQuestionIndex].options.map(
                              (option, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 8,
                                  }}
                                >
                                  <Input
                                    size="large"
                                    style={{ width: "calc(100% - 32px)" }}
                                    placeholder={`اكتب الاختيار ${index + 1}`}
                                    value={option?.title}
                                    // ...
                                    onChange={(e) => {
                                      setQuestionData((prevQuestionData) => {
                                        const updatedQuestionData = [
                                          ...prevQuestionData,
                                        ];
                                        const currentQuestion = {
                                          ...updatedQuestionData[
                                            currentQuestionIndex
                                          ],
                                        };

                                        currentQuestion.options = [
                                          ...currentQuestion.options.slice(
                                            0,
                                            index
                                          ),
                                          { title: e.target.value }, // Assuming options is an array of objects with a title field
                                          ...currentQuestion.options.slice(
                                            index + 1
                                          ),
                                        ];

                                        updatedQuestionData[
                                          currentQuestionIndex
                                        ] = currentQuestion;
                                        return updatedQuestionData;
                                      });
                                    }}
                                    // ...
                                  />

                                  <Checkbox
                                    checked={
                                      questionData[currentQuestionIndex]
                                        .optionsCorrect[index]
                                    }
                                    onChange={(e) => {
                                      const updatedQuestionData = [
                                        ...questionData,
                                      ];
                                      updatedQuestionData[
                                        currentQuestionIndex
                                      ].optionsCorrect[index] = e.target.checked
                                        ? 1
                                        : 0;
                                      setQuestionData(updatedQuestionData);
                                    }}
                                  />
                                  <Button
                                    type="text"
                                    icon={
                                      <MinusCircleOutlined
                                        style={{
                                          fontSize: 20,
                                          color: "#FF6905",
                                        }}
                                      />
                                    }
                                    size="small"
                                    style={{ border: "none" }}
                                    onClick={() => {
                                      setQuestionData((prevQuestionData) => {
                                        const updatedQuestionData = [
                                          ...prevQuestionData,
                                        ];
                                        const currentQuestion = {
                                          ...updatedQuestionData[
                                            currentQuestionIndex
                                          ],
                                        };

                                        currentQuestion.options.splice(
                                          index,
                                          1
                                        );
                                        currentQuestion.optionsCorrect.splice(
                                          index,
                                          1
                                        );

                                        updatedQuestionData[
                                          currentQuestionIndex
                                        ] = currentQuestion;
                                        return updatedQuestionData;
                                      });
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </Flex>
                        </Form.Item>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: 20,
                          gap: 30,
                        }}
                      >
                        {currentQuestionIndex ===
                          exam?.exam?.questions.length - 1 && (
                          <Button
                            onClick={() => {
                              saveUpdateBtn();
                            }}
                            size="large"
                            style={{
                              backgroundColor: "rgb(17, 20, 45)",
                              border: "1px solid rgb(163, 163, 165)",
                              borderRadius: 16,

                              color: " rgb(255, 255, 255)",
                            }}
                          >
                            تعديل
                          </Button>
                        )}
                        {currentQuestionIndex !==
                          exam?.exam?.questions.length - 1 && (
                          <Button
                            size="large"
                            style={{
                              backgroundColor: "rgb(17, 20, 45)",
                              border: "1px solid rgb(163, 163, 165)",
                              borderRadius: 16,

                              color: " rgb(255, 255, 255)",
                            }}
                            onClick={handleNextQuestion}
                            disabled={
                              currentQuestionIndex ===
                              exam?.exam?.questions.length - 1
                            }
                          >
                            التالي
                          </Button>
                        )}
                        <Button
                          size="large"
                          style={{
                            //backgroundColor: "rgb(17, 20, 45)",
                            border: "1px solid rgb(163, 163, 165)",
                            borderRadius: 16,

                            //  color: " rgb(255, 255, 255)",
                          }}
                          onClick={handlePreviousQuestion}
                          disabled={currentQuestionIndex === 0}
                        >
                          السابق
                        </Button>
                      </div>
                    </div>
                  )
              )}
            </Form>
          </div>
        )}
      </div>
    </>
  );
};

export default EditExamForm;
