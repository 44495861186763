import "./Login.scss";
import React from "react";
import { Form, Input, Typography, Button, App } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { login, selectLogin } from "../../redux//slices/authSlice";
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../components/common/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
const Login = () => {
  const info = useSelector(selectLogin);
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "خطأ",
      description: "خطأ في كلمة المرور أو الحساب.",
    });
  };

  const onFinish = (values) => {
    console.log(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const authError = useSelector((state) => state.auth.error);
  const auth = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState("دخول");
  const handleLogin = (e) => {
    e.preventDefault();

    const credentials = {
      phone: phone,
      password: password,
    };
    setLoading(<LoadingOutlined />);
    dispatch(login(credentials));

    // console.log(auth);

    // console.log(auth);
  };
  const phoneRegex = /^[+\d]+$/;

  if (auth) {
    if (localStorage.getItem("token") && auth?.role == "5") {
      window.localStorage.setItem("isLogedIn", true);
      navigate("/Dashboard");
    } else if (localStorage.getItem("token") && auth?.role == "4") {
      window.localStorage.setItem("isLogedIn", true);
      navigate("/Dashboard");
    }
  }
  useEffect(() => {
    if (authError) {
      openNotificationWithIcon("error");
      setLoading("دخول");
    } else {
      setError("");
    }
  }, [authError]);
  return (
    <div className="formContainer">
      <div className="imageLogin">
        <img
          src="login.png"
          alt=""
          style={{
            height: "75%",
            width: "75%",
            //border: "red 1px solid",
            //  margin: 50,
          }}
        />
      </div>

      <Form
        layout="vertical"
        className="loginForm"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Typography.Title className="titleForm">تسجيل الدخول</Typography.Title>
        <div
          style={{
            display: "flex",
            // flexDirection: "row-reverse",
            margin: 10,
          }}
        >
          <Typography className="labelForm">رقم الموبايل</Typography>
        </div>
        <Form.Item
          name="phone"
          rules={[
            {
              validator: (_, value) => {
                // Regular expression to check if the phone number is valid

                // Check if the phone number matches the regex
                if (!phoneRegex.test(value)) {
                  return Promise.reject("Please enter a valid phone number");
                }

                // If the phone number is valid, resolve the Promise
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              border: " 1px solid #A3A3A5",
              borderRadius: 10,
              width: "70%",
            }}
            size="large"
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            // flexDirection: "row-reverse",
            margin: 10,
          }}
        >
          <Typography className="labelForm">كلمة السر</Typography>
        </div>
        <Form.Item
          name="password"
          rules={[
            {
              //  required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              border: " 1px solid #A3A3A5",
              borderRadius: 10,
              width: "70%",
            }}
            size="large"
          />
        </Form.Item>

        <Form.Item style={{ padding: 10, paddingTop: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="buttonForm"
            onClick={handleLogin}
            disabled={!phone || !password || !/^[\d+]+$/.test(phone)}
            loading={false}
            size="large"
          >
            {loading}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
