import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  Typography,
  DatePicker,
  message,
  Checkbox,
  Upload,
  Space,
  Flex,
  App,
} from "antd";
import {
  UploadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import Home from "../home/Home";
import { createExam, resetStatus } from "../../redux/slices/Questions";
import { useDataContext } from "../../context/DataContext";
const { Dragger } = Upload;

const Questions = () => {
  const [question_title, questionTitle] = useState("");
  const [question_description, setDescription] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [question_marks, setMark] = useState(null);
  // const [TypeofAnswer, setTypeofAnswer] = useState("");
  const [showAdditionalFormItem, setShowAdditionalFormItem] = useState(false);
  const [type, setQuestionType] = useState("text");
  const [options, setOptions] = useState(["", "", ""]);
  const [options_correct, setOptions_correct] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { notification } = App.useApp();
  const dispatch = useDispatch();

  const { status, error } = useSelector((state) => state.exam);

  const [optionsCorrect, setOptionsCorrect] = useState([false, false, false]);
  const [formData, setFormData] = useState(new FormData());
  const [questions, setQuestions] = useState([]);
  const handleAddOption = () => {
    setOptions([...options, ""]);
    setOptionsCorrect([...optionsCorrect, false]);
  };
  //const dispatch = useDispatch();
  const success = () => {
    messageApi.open({
      type: "success",
      content: "تمت اضافة سؤال",
    });
  };

  const handleAddQuestionClick = () => {
    // Validate the input fields
    dispatch(resetStatus());
    if (!questionData || questionData === null) {
      notification.error({ message: "لايوجد معلومات للامتحان .. رجوع" });
      return;
    } else if (
      question_title.trim() === "" ||
      question_description.trim() === "" ||
      question_marks === null ||
      (type === "file" && File === null) ||
      (type === "options" && options.some((option) => option.trim() === ""))
    ) {
      // notification.error({ message: "يرجى تعبئة الحقول بشكل صحيح." });
      notification.error({ message: "يرجى ملء جميع الحقول المطلوبة." });
      return;
    } else if (Number(question_marks) > Number(questionData?.totalMarks)) {
      notification.error({
        message:
          questionData?.totalMarks +
          "  ان علامة السؤال اكبر من علامة الامتحان " +
          question_marks,
      });
      return;
    }

    // Calculate the sum of existing question_marks
    const currentTotalMarks = questions.reduce(
      (total, q) => total + Number(q.question_marks),
      0
    );

    // Check if adding the current question would exceed the totalMarks
    if (
      currentTotalMarks + Number(question_marks) >
      Number(questionData?.totalMarks)
    ) {
      notification.error({
        message: "مجموع علامات الأسئلة تجاوز إجمالي علامات الامتحان.",
      });
      return;
    }

    // Create an array of objects for each option
    const questionOptions =
      type === "options"
        ? options.map((option, index) => ({
            option,
            options_correct: optionsCorrect[index],
          }))
        : undefined;

    // Add the current question to the array of questions
    const currentQuestion = {
      question_title,
      question_description,
      question_marks,
      type,
      options: type === "options" ? questionOptions : undefined,
      file: type === "file" ? File : null,
    };

    setQuestions([...questions, currentQuestion]);

    // Reset input fields
    questionTitle("");
    setDescription("");
    setMark(null);
    setQuestionType("نص");
    setOptions(["", "", ""]);
    setOptionsCorrect([false, false, false]);
    setUploadedFile(null);
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = [...options];
    const updatedOptionsCorrect = [...optionsCorrect];

    updatedOptions.splice(index, 1); // Remove the option at the specified index
    updatedOptionsCorrect.splice(index, 1); // Remove the corresponding correct option flag

    setOptions(updatedOptions);
    setOptionsCorrect(updatedOptionsCorrect);
  };

  const questionOptions = options
    .filter((option) => option.trim() !== "")
    .map((option, index) => ({
      option,
      optionsCorrect: optionsCorrect[index],
    }));
  const navigate = useNavigate();
  let { questionData } = useDataContext();
  const [File, setUploadedFile] = useState(null);
  const handleFileUpload = (info) => {
    // message.success(`${info?.file?.name} file uploaded successfully.`);

    formData.append("file", info?.file);
    setUploadedFile(info?.file);
    if (status === "done") {
    } else if (status === "error") {
      notification.error({ message: ` file upload failed.` });
      setUploadedFile(null);
    }
  };

  const examData = {
    title: questionData?.title,
    description: questionData?.description,
    start_date: questionData?.startDateTimeString,
    end_date: questionData?.endDateTimeString,
    total_marks: questionData?.totalMarks,
    course_id: questionData?.id,
    questions: questions,
  };
  // Dispatch the createExam action with the examData
  const sub = async () => {
    try {
      //  await
      if (status == "loading" || status == "idle") {
        notification.info({ message: "جاري رفع الاختبار " });
       const response =await  dispatch(createExam(examData));
      //  console.log(response.payload.status==200);
       if (response.payload.status == 200) {
         notification.success({ message: "تم رفع الامتحان" });
         questionData = null;
         navigate("/courses");
         dispatch(resetStatus());
       }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error as needed
    }
    
  };
  return (
    <Home>
      <Form
        layout="vertical"
        encType="multipart/form-data"
        style={{ height: "100%", width: "100%" }}
        onFinish={() => {
          if (questions.length === 0) {
            notification.error({ message: "يرجى اضافة سؤال واحد على الاقل " });
          } else {
            sub();
          }
        }}
      >
        <div className="ExamContainer">
          {contextHolder}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 0,
              height: "100%",
              flex: 10,
            }}
          >
            <div className="live">
              <Typography className="live-title">اضافة سؤال</Typography>
            </div>

            <div style={{ marginRight: 40, marginTop: 0, marginLeft: 20 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  paddingBottom: 20,
                }}
              >
                <Typography className="question-numbers">
                  معلومات السؤال
                </Typography>
              </div>
              <Form.Item label={"العنوان"}>
                <Input
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="اكتب العنوان"
                  value={question_title}
                  onChange={(e) => questionTitle(e.target.value)}
                />
              </Form.Item>

              <Form.Item label={"السؤال"}>
                <Input
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="اكتب السؤال"
                  value={question_description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Item>
              <Form.Item label={"العلامة السؤال"}>
                <Input
                  type="number"
                  size="large"
                  style={{ width: "66%" }}
                  placeholder="علامة  السؤال"
                  value={question_marks}
                  onChange={(e) => setMark(e.target.value)}
                />
              </Form.Item>
              <Form.Item label={"نوع السؤال"}>
                <Select
                  size="large"
                  style={{ width: "66%" }}
                  value={type}
                  onChange={(value) => setQuestionType(value)}
                >
                  <Select.Option value="text">نص</Select.Option>
                  <Select.Option value="file">ملف</Select.Option>
                  <Select.Option value="options">اختياري</Select.Option>
                </Select>
              </Form.Item>
              {type === "options" && (
                <Form.Item label={"اختياري"}>
                  <Space style={{ width: "100%" }}>
                    <Button
                      type="text"
                      icon={
                        <PlusCircleOutlined
                          style={{ fontSize: 20, color: "#2F3CED" }}
                        />
                      }
                      size="small"
                      style={{ border: "none" }}
                      onClick={handleAddOption}
                    />

                    <Flex wrap="wrap" justifyContent={"space-between"} gap={12}>
                      {options.map((option, index) => (
                        <div
                          key={index}
                          style={{
                            width: "30%",
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          <Input
                            size="large"
                            style={{ width: "calc(100% - 32px)" }}
                            placeholder={`اكتب الاختيار ${index + 1}`}
                            value={option}
                            onChange={(e) => {
                              const updatedOptions = [...options];
                              updatedOptions[index] = e.target.value;
                              setOptions(updatedOptions);
                            }}
                          />
                          <Checkbox
                            checked={optionsCorrect[index]}
                            onChange={(e) => {
                              const updatedOptionsCorrect = [...optionsCorrect];
                              updatedOptionsCorrect[index] = e.target.checked;
                              setOptionsCorrect(updatedOptionsCorrect);
                            }}
                          />
                          <Button
                            type="text"
                            icon={
                              <MinusCircleOutlined
                                style={{ fontSize: 20, color: " #FF6905" }}
                              />
                            }
                            size="small"
                            style={{ border: "none" }}
                            onClick={() => handleDeleteOption(index)}
                          />
                        </div>
                      ))}
                    </Flex>
                  </Space>
                </Form.Item>
              )}

              <Form.Item>
                <Button
                  type="text"
                  onClick={handleAddQuestionClick}
                  size="large"
                  style={{ border: "none" }}
                >
                  إضافة سؤال
                </Button>
              </Form.Item>
            </div>
            <div>
              <Button
                size="large"
                style={{
                  color: "#fff",
                  backgroundColor: "#11142D",
                  borderRadius: 16,
                  width: "15%",
                  marginRight: "10px",
                }}
                // onClick={sub}
                htmlType="submit"
              >
                حفظ
              </Button>
              <Button
                size="large"
                style={{
                  color: "#11142D",
                  backgroundColor: "#FFF",
                  borderRadius: 16,
                  width: "15%",
                  marginRight: "10px",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                رجوع
              </Button>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#DDEDFF80",
              display: "flex",
              flex: "5",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            {type === "file" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                  padding: 20,
                  justifyContent: "flex-start",
                  height: "80%",
                }}
              >
                <Typography
                  style={{
                    color: "#2F3CED",
                    fontSize: 36,
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  ارفع ملف الاختبار
                </Typography>
                <Form.Item name={"FileUploader"}>
                  <Dragger
                    name="file"
                    multiple
                    //  onChange={handleFileUpload}
                    onDrop={(e) => {
                      // console.log("Dropped files", e.dataTransfer.files);
                    }}
                    onChange={(info) => handleFileUpload(info)}
                    beforeUpload={(file) => {
                      const allowedFileTypes = [
                        "application/pdf",
                        "image/jpeg",
                        "image/png",
                      ];
                      if (!allowedFileTypes.includes(file.type)) {
                        notification.error({
                          message:
                            "Invalid file type. Please upload a PDF, JPG, or PNG file.",
                        });
                        return false; // Cancel the upload
                      }

                      // Handle the file upload logic here
                      handleFileUpload({ file });
                      // Continue with the default upload behavior
                      return false;
                    }}
                    style={{
                      backgroundColor: "#fff",
                      border: "1px solid #A3A3A5",
                      borderRadius: 18,
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined
                        style={{ fontSize: 20, color: "#11142D" }}
                      />
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: 0,
                      }}
                    >
                      <b> اضغط للرفع</b>
                      <p className="ant-upload-text">أو السحب و الافلات</p>
                      <p className="ant-upload-hint">
                        PNG, JPEG or JPG (max 800*800px)
                      </p>
                    </div>
                  </Dragger>
                </Form.Item>
              </div>
            )}
          </div>
        </div>
      </Form>
    </Home>
  );
};

export default Questions;
