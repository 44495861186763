import React, { useEffect, useState } from "react";
import { message, Result, Upload } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { SmileOutlined, LoadingOutlined } from "@ant-design/icons";
import "../../pages/examquestiontype1/ExamQuestionType1.scss";
import { postStudentAnswers } from "../../redux/slices/Questions";
import { fetchStudentExams, clearError } from "../../redux/slices/Questions";
import DefaultPageLoader from "../../components/loaders/DefaultPageLoader";
import MainExam from "../../components/Cards/MainExam";

export default function ExamQuestionType({ id, setShowExamForm }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const exams = useSelector((state) => state.exam.exam_s);
  const [title, setTitle] = useState("");
  const [examData, seExamData] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { status, error } = useSelector((state) => state.exam);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);

  //   const { id } = useParams();
  const [questionStates, setQuestionStates] = useState([]);
  const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
  const updateQuestionState = (index, fieldName, value) => {
    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = { ...newStates[index], [fieldName]: value };

      return newStates;
    });
  };
  const [loading, setLoading] = useState("تنزيل");
  useEffect(() => {
    if (localStorage.getItem("role") == "5" && exams) {
      const loadExams = async () => {
        await dispatch(fetchStudentExams(id));
        dispatch(clearError());
      };

      loadExams();
    }
  }, []);

  const handleAddQuestionClick = () => {
    const questionData = exams?.exam?.questions.map((question, index) => {
      if (question.type === "text") {
        return {
          id: question.id,
          type: question.type,
          answer: questionStates[index]?.textAnswer || "لم يتم الاجابة ",
        };
      } else if (question.type === "options") {
        return {
          id: question.id,
          type: question.type,
          options: question.options.map((option) => ({
            id: option.id,
            option: option.title,
            options_selected:
              option.id === questionStates[index]?.selectedOption,
          })),
        };
      } else if (question.type === "file") {
        return {
          id: question.id,
          type: question.type,
          file: questionStates[index]?.file || uploadedFile || null,
        };
      }
      return null;
    });

    seExamData({
      exam_id: exams?.exam?.id,
      questions: questionData.filter((q) => q !== null),
    });
    setTitle(exams?.exam?.title);
  };

  const handleNextQuestion = () => {
    const currentQuestion = exams?.exam?.questions[currentQuestionIndex];

    if (
      currentQuestion.type === "text" &&
      !questionStates[currentQuestionIndex]?.textAnswer
    ) {
      message.error("يرجى الاجابة على السؤال");
      return;
    }
    if (
      currentQuestion.type === "options" &&
      !questionStates[currentQuestionIndex]?.selectedOption
    ) {
      message.error("يرجى اختيار الجواب الصحيح");
      return;
    }

    // Add a validation check for file type questions
    if (
      currentQuestion.type === "file" &&
      !questionStates[currentQuestionIndex]?.file
    ) {
      message.error("يرجى رفع الملف");
      return;
    }

    if (currentQuestionIndex < exams?.exam?.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const allQuestionsAnswered = questionStates.every(
        (state) => state?.textAnswer || state?.selectedOption
      );

      // Add a validation check for file type questions

      if (exams?.exam?.questions?.length === questionStates?.length) {
        // Display the modal form
        setIsModalVisible(true);
        handleAddQuestionClick();
      } else {
        // Handle the end of the exam without a modal form
        handleAddQuestionClick();
      }
    }
  };

  const handleModalOk = async () => {
    const response = await dispatch(postStudentAnswers(examData));
    setErrorResponse(response);
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  const handleFileUpload = (info) => {
    if (info.file && info.file.status === "done") {
      setUploadedFile(info.file.originFileObj);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file && info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const downloadFile = async (file) => {
    try {
      setLoading(<LoadingOutlined />);

      const response = await fetch(file, { redirect: "follow" });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;

      // Extract filename from the URL (assuming the filename is at the end of the URL)
      const filename = file.substring(file.lastIndexOf("/") + 1);
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading("تنزيل");

      window.open(file, "_blank");
    }
  };

  return (
    <>
      {status === "loading" || status === "idle" ? (
        <div className="main-exam">
          <DefaultPageLoader />
        </div>
      ) : status == "succeeded" && error == null ? (
        <MainExam
          onCancel={setShowExamForm}
          exams={exams}
          currentQuestionIndex={currentQuestionIndex}
          questionStates={questionStates}
          updateQuestionState={updateQuestionState}
          downloadFile={downloadFile}
          loading={loading}
          handleFileUpload={handleFileUpload}
          allowedFileTypes={allowedFileTypes}
          message={message}
          handlePreviousQuestion={handlePreviousQuestion}
          handleNextQuestion={handleNextQuestion}
          isModalVisible={isModalVisible}
          handleModalCancel={handleModalCancel}
          handleModalOk={handleModalOk}
        />
      ) : error != null ? (
        <div>
          <Result icon={<SmileOutlined />} title={error} />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
