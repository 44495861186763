import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio, Typography, Flex } from "antd";
import Dragger from 'antd/es/upload/Dragger';
import React from 'react'
import { ArrowLeftOutlined } from "@ant-design/icons";
import PageTitle from '../Title/PageTitle';

const {TextArea} = Input

export default function MainExam({
  exams,
  currentQuestionIndex,
  questionStates,
  updateQuestionState,
  downloadFile,
  loading,
  handleFileUpload,
  allowedFileTypes,
  message,
  handlePreviousQuestion,
  handleNextQuestion,
  isModalVisible,
  handleModalCancel,
  handleModalOk,
  onCancel
}) {
  return (
    <div className="main-exam">
      <div className="live-answers">
        <div>
          <Flex justify={"space-between"} align="center">
            <PageTitle>{exams?.exam?.title}</PageTitle>
            <Button type={"text"} onClick={() => onCancel(false)}>
              <ArrowLeftOutlined />
            </Button>
          </Flex>
        </div>
        <Typography className="related-title">
          {exams?.exam?.description}
        </Typography>
        <div className="page-number-container">
          <span className="related-title">
            صفحة <span className="page-number">{currentQuestionIndex + 1}</span>{" "}
            من {exams?.exam?.questions.length}
          </span>
        </div>
      </div>

      <Form>
        {exams?.exam?.questions.map((question, index) => (
          <div
            key={question?.id}
            style={{
              display: index === currentQuestionIndex ? "block" : "none",
            }}
          >
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  width: "100%",
                }}
              >
                <div className="question-number">
                  <Typography className="page-number">{index + 1}</Typography>
                </div>
                <Typography className="related-title">
                  {question?.title} : {question?.description} ؟
                </Typography>
              </div>
            </Form.Item>
            {question.type === "text" && (
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "30%",
                    justifyContent: "space-between",
                    gap: 20,
                  }}
                >
                  <TextArea
                    placeholder={question?.description}
                    style={{
                      height: 170,
                      resize: "none",
                      borderRadius: 16,
                    }}
                    value={questionStates[index]?.textAnswer || ""}
                    onChange={(e) =>
                      updateQuestionState(index, "textAnswer", e.target.value)
                    }
                  />
                </div>
              </Form.Item>
            )}
            {question.type === "options" && (
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "80%",
                    justifyContent: "flex-start ",
                  }}
                >
                  <Radio.Group
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      color: "#11142D",
                    }}
                    value={questionStates[index]?.selectedOption || undefined}
                    onChange={(e) =>
                      updateQuestionState(
                        index,
                        "selectedOption",
                        e.target.value
                      )
                    }
                  >
                    {question.options.map((option) => (
                      <div
                        key={option.id}
                        style={{
                          border: "1px solid #808191",
                          borderRadius: 14,
                          width: "25%",
                        }}
                      >
                        <Radio value={option.id} style={{ padding: 10 }}>
                          {option.title}
                        </Radio>
                      </div>
                    ))}
                  </Radio.Group>
                </div>
              </Form.Item>
            )}
            {question.type === "file" && (
              <Form.Item>
                <Button
                  size="large"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: 16,
                    color: "#11142d",
                  }}
                  onClick={() => {
                    downloadFile(question?.file);
                  }}
                >
                  {loading}
                </Button>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      // justifyContent: "space-between",
                      gap: 20,
                    }}
                  >
                    <Typography className="related-title">
                      ملف الاجابة :
                    </Typography>
                    <Dragger
                      name="file"
                      multiple
                      //  onChange={handleFileUpload}
                      onDrop={(e) => {
                        console.log("Dropped files", e.dataTransfer.files);
                      }}
                      onChange={(info) => handleFileUpload(info)}
                      beforeUpload={(file) => {
                        if (!allowedFileTypes.includes(file.type)) {
                          message.error("JPG,PNG,JPEG,PDF  فقط!");
                          return false; // Cancel the upload
                        }
                        updateQuestionState(index, "file", file);

                        // Handle the file upload logic here
                        // handleFileUpload({ file });
                        // Continue with the default upload behavior
                        return false;
                      }}
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #A3A3A5",
                        borderRadius: 18,
                        width: 400,
                        height: "100%",
                        padding: 12,
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined
                          style={{ fontSize: 20, color: "#11142D" }}
                        />
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          padding: 0,
                        }}
                      >
                        <b> اضغط للرفع</b>
                        <p className="ant-upload-text">أو السحب و الافلات</p>
                        <p className="ant-upload-hint">
                          PNG, JPEG or JPG (max 800*800px)
                        </p>
                      </div>
                    </Dragger>
                  </div>
                </Form.Item>
              </Form.Item>
            )}
          </div>
        ))}
        <div>
          <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
            {currentQuestionIndex > 0 && (
              <Button
                style={{
                  border: "1px solid #A3A3A5",
                  borderRadius: 16,
                  color: "#fff",
                }}
                onClick={handlePreviousQuestion}
                size="large"
              >
                السؤال السابق
              </Button>
            )}
            <Button
              style={{
                backgroundColor: "#11142D",
                border: "1px solid #A3A3A5",
                borderRadius: 16,
                color: "#fff",
              }}
              onClick={handleNextQuestion}
              size="large"
            >
              {currentQuestionIndex < exams?.exam?.questions.length - 1
                ? "السؤال التالي"
                : "انهاء الاختبار"}
            </Button>
          </div>
        </div>
        <Modal
          width={"35%"}
          title={
            <Typography style={{ textAlign: "center", fontSize: 40 }}>
              هل أنت متأكد؟
            </Typography>
          }
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={(_, {}) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="large"
                onClick={handleModalCancel}
                style={{
                  backgroundColor: "#fff",
                  color: "#11142D",
                  border: "1px solid #11142D",
                  width: "25%",
                  borderRadius: 16,
                }}
              >
                الغاء
              </Button>
              <Button
                size="large"
                onClick={() => {
                  handleModalOk();
                  // navigate("/dashboard");
                }}
                style={{
                  backgroundColor: "#11142D",
                  color: "#fff",
                  width: "25%",
                  borderRadius: 16,
                }}
              >
                تأكيد
              </Button>
            </div>
          )}
        >
          <p
            style={{
              textAlign: "center",
              color: "#11142D",
              fontSize: "16px",
            }}
          >
            يرجى التأكد من جميع الاجوبة لانه لا يمكن تغييرها بعد هذه المرحلة!
          </p>
        </Modal>
      </Form>
    </div>
  );
}
