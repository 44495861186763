import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Input,
  Form,
  Upload,
  message,
  Row,
  Col,
  Modal,
} from "antd";
import { PlusOutlined, MinusOutlined, UploadOutlined } from "@ant-design/icons";
import "./AddNewExam.scss";
import { postStudentAnswers } from "../../redux/slices/Questions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const AddExamUpload = ({ file, title, examData, fileTitle }) => {
  const [loading, setLoading] = useState("تنزيل");
  const { Dragger } = Upload;
  const count = 10;

  const dispatch = useDispatch();
  const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
  const handleFileUpload = (info) => {
    if (examData?.questions && examData.questions.length > 0) {
      // Find the first question with a null file
      const questionWithNullFile = examData.questions.find(
        (question) => question.type === "file" && question.file === null
      );

      if (questionWithNullFile) {
        // Update the file information for the found question
        questionWithNullFile.file = info?.file;
      }
    }
  };

  useEffect(() => {
    // You can use the 'file' and 'title' props here
  }, [file, title, examData, fileTitle]);

  const downloadFile = async () => {
    try {
      setLoading(<LoadingOutlined />);

      const response = await fetch(file, { redirect: "follow" });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;

      // Extract filename from the URL (assuming the filename is at the end of the URL)
      const filename = file.substring(file.lastIndexOf("/") + 1);
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading("تنزيل");
      // If there is an error, open the file in another tab
      window.open(file, "_blank");
    }
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    dispatch(postStudentAnswers(examData));
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const handleSave = () => {
    // Dispatch the postStudentAnswers thunk with the exam data
    dispatch(postStudentAnswers(examData));
  };
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <div className="correctContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 0,
              // border: "1px red solid",
              height: "100%",
              flex: 10,
            }}
          >
            <div className="live-answers">
              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <Typography className="live-title">{title}</Typography>
              </div>
              <Typography className="related-title">
                نزل ملف الاسئلة و اجب عليها, ثم ارفعها و اضغط حفظ
              </Typography>
            </div>

            <Form>
              {" "}
              <div style={{ marginRight: 40, marginTop: 30, marginLeft: 20 }}>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      width: "100%",
                    }}
                  >
                    <Typography className="related-title">
                      {fileTitle}
                    </Typography>
                    <Button
                      size="large"
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 16,
                        color: "#11142d",
                      }}
                      onClick={downloadFile}
                    >
                      {loading}
                    </Button>
                  </div>
                </Form.Item>

                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      // justifyContent: "space-between",
                      gap: 20,
                    }}
                  >
                    <Typography className="related-title">
                      ملف الاجابة :
                    </Typography>
                    <Dragger
                      name="file"
                      multiple
                      //  onChange={handleFileUpload}
                      onDrop={(e) => {
                        // console.log("Dropped files", e.dataTransfer.files);
                      }}
                      onChange={(info) => handleFileUpload(info)}
                      beforeUpload={(file) => {
                        if (!allowedFileTypes.includes(file.type)) {
                          message.error(
                            "Invalid file type. Please upload a valid file type."
                          );
                          return false; // Cancel the upload
                        }

                        // Handle the file upload logic here
                        handleFileUpload({ file });
                        // Continue with the default upload behavior
                        return false;
                      }}
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #A3A3A5",
                        borderRadius: 18,
                        width: 400,
                        height: "100%",
                        padding: 12,
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined
                          style={{ fontSize: 20, color: "#11142D" }}
                        />
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          padding: 0,
                        }}
                      >
                        <b> اضغط للرفع</b>
                        <p className="ant-upload-text">أو السحب و الافلات</p>
                        <p className="ant-upload-hint">
                          PNG, JPEG or JPG (max 800*800px)
                        </p>
                      </div>
                    </Dragger>
                  </div>
                </Form.Item>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                    width: "15%",
                    padding: 10,
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#11142D",
                      border: "1px solid #A3A3A5",
                      borderRadius: 16,
                      width: "100%",
                      color: "#fff",
                    }}
                    size="large"
                    onClick={showModal}
                  >
                    حفظ
                  </Button>
                  <Modal
                    width={"35%"}
                    //  height={"100%"}
                    open={open}
                    title={
                      <Typography style={{ textAlign: "center", fontSize: 40 }}>
                        هل أنت متأكد؟
                      </Typography>
                    }
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={(_, {}) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          size="large"
                          onClick={handleOk}
                          style={{
                            backgroundColor: "#fff",
                            color: "#11142D",
                            border: "1px solid #11142D",
                            width: "25%",
                            borderRadius: 16,
                          }}
                        >
                          الغاء
                        </Button>
                        <Button
                          size="large"
                          onClick={() => {
                            handleOk();
                            // navigate("/dashboard");
                          }}
                          style={{
                            backgroundColor: "#11142D",
                            color: "#fff",
                            width: "25%",
                            borderRadius: 16,
                          }}
                        >
                          تأكيد
                        </Button>
                      </div>
                    )}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "#11142D",
                        fontSize: "16px",
                      }}
                    >
                      يرجى التأكد من جميع الاجوبة لانه لا يمكن تغييرها بعد هذه
                      المرحلة!
                    </p>
                  </Modal>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default AddExamUpload;
