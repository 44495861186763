import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../helpers/constants";
import { apiClient } from "../../helpers/api-client";

const API_URL_Student = `${API_BASE_URL}/student/my-courses`;

const COURSES_API_URL = `${API_BASE_URL}/courses`;
const SINGLE_COURSE_URL = `${API_BASE_URL}/courses/`;

const initialState = {
  courses: [],
  singleTopic: {} | null,
  status: "idle",
  error: null,
};

export const fetchCourses = createAsyncThunk(
  "courses/fetchCourses",
  async () => {
    const token = localStorage.getItem("token");

    // if (!token) {
    //   throw new Error("Bearer token not found in localStorage");
    // }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.get(API_URL_Student, { headers });
    return response.data;
  }
);

export const fetchTeacherCourses = createAsyncThunk(
  "courses/fetchTeacherCourses",
  async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("Bearer token not found in localStorage");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.get(`${API_BASE_URL}/teacher/my-courses`, {
      headers,
    });
    return response.data;
  }
);

console.log(localStorage.getItem("token"));
export const fetchAllCourses = createAsyncThunk(
  "courses/fetchAllCourses",
  async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("Bearer token not found in localStorage");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.get(COURSES_API_URL, { headers });
    return response.data;
  }
);

export const fetchAllStudentsInCourse = createAsyncThunk(
  "courses/fetchAllStudentsInCourse",
  async (id) => {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("Bearer token not found in localStorage");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.get(
      `${API_BASE_URL}/teacher/students-course/${id}`,
      { headers }
    );
    return response.data;
  }
);
export const fetchSingleCourse = createAsyncThunk(
  "courses/fetchSingleCourse",
  async (id) => {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("Bearer token not found in localStorage");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const url = `${SINGLE_COURSE_URL}${id}`;
    const response = await axios.get(url, { headers });
    // console.log(response.data);
    return response.data;
  }
);

export const fetchLessonDetails = createAsyncThunk(
  "courses/fetchSingleCourse/fetchLessonDetails",
  async (lessonId) => {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("Bearer token not found in localStorage");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const url = `${SINGLE_COURSE_URL}/${lessonId}`;
    const response = await axios.get(url, { headers });
    // console.log(response.data);
    return response.data;
  }
);

export const fetchTopicDetails = createAsyncThunk(
  "courses/fetchSingleCourse/fetchTopicDetails",
  async (topicId) => {
    const token = localStorage.getItem("token");

    // if (!token) {
    //     throw new Error("Bearer token not found in localStorage");
    // }

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const url = `/teacher/topics/${topicId}`;
    const response = await apiClient.get(url, { headers: headers });
    return response.data;
  }
);

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.courses = action.payload;
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllCourses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCourses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.courses = action.payload;
      })
      .addCase(fetchAllCourses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTeacherCourses.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeacherCourses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.courses = action.payload;
      })
      .addCase(fetchTeacherCourses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllStudentsInCourse.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllStudentsInCourse.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.courses = action.payload;
      })
      .addCase(fetchAllStudentsInCourse.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSingleCourse.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSingleCourse.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Assuming you want to store the single course in the state
        state.singleCourse = action.payload;
      })
      .addCase(fetchSingleCourse.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchLessonDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLessonDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Assuming you want to store the single course in the state
        state.singleCourse = action.payload;
      })
      .addCase(fetchLessonDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchTopicDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTopicDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Assuming you want to store the single course in the state
        state.singleTopic = action.payload;
      })
      .addCase(fetchTopicDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectCourses = (state) => state.courses.courses;
export const selectStatus = (state) => state.courses.status;
export const selectError = (state) => state.courses.error;
export const selectAllCourses = (state) => state.courses.courses;

export const selectLessonDetails = (state) => state.singleCourse;
export const selectTopicDetails = (state) => state.singleTopic;

export const selectAllCoursesStatus = (state) => state.courses.status;
export const selectAllCoursesError = (state) => state.courses.error;
export const selectTeacherCourses = (state) => state.courses.courses;
export const selectTeacherStatus = (state) => state.courses.status;
export const selectTeacherError = (state) => state.courses.error;
export const selectAllStudentsInCourseStudents = (state) =>
  state.courses.students;
export const selectAllStudentsInCourseStatus = (state) => state.courses.status;
export const selectAllStudentsInCourseError = (state) => state.courses.error;
export const selectSingleCourse = (state) => state.courses.singleCourse;
//export const selectCourseTopics = (state) => state.courses.topic;
export default coursesSlice.reducer;
