import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Result, Typography, App, Spin, Space, Flex } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Home from "../home/Home";
import {
  fetchCourses,
  selectCourses,
  selectStatus,
  fetchTeacherCourses,
  selectTeacherCourses,
} from "../../redux/slices/coursesSlice";
import { Button } from "antd";
import "./ExploreCuerces.scss";
import { fetchStudentExams } from "../../redux/slices/Questions";
import TopicsList from "../../components/lists/TopicsList";
import TeacherCourseCard from "../../components/Cards/TeacherCourseCard";
import StudentCourseCard from "../../components/Cards/StudentCourseCard";
import VideoPlayer from "../videoplay/VideoPlayer";
import AddButtonsActions from "../../components/DropDowns/AddButtonsActions";
import ExamCard from "../../components/Cards/ExamCard";
import AddLiveSessionForm from "../../components/forms/AddLiveSessionForm";
import AddLessonForm from "../../components/forms/AddLessonForm";
import AddExamForm from "../../components/forms/AddExamForm";
import PageTitle from "../../components/Title/PageTitle";
import DefaultPageLoader from "../../components/loaders/DefaultPageLoader";
import ExamQuestionType from "../../components/Cards/ExamQuestionType";
import EditExamForm from "../../components/forms/EditExamForm";

const Courses = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector(selectStatus);
  const courses_t = useSelector(selectCourses || selectTeacherCourses);
  const exams = useSelector((state) => state.exam.exam_s);
  const [description, setDescription] = useState(
    "اختر دورة لتتعرف على التفاصيل"
  );
  const role = localStorage.getItem("role");
  const [selectedCard, setSelectedCard] = useState(null);
  const [courseName, setCourseName] = useState("عنوان الدورة");
  const [display, setDisplay] = useState("none");
  const [courseDetails, setCourseDetails] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showAddLiveSessionForm, setShowAddLiveSessionForm] = useState(false);
  const [showAddLessonForm, setShowAddLessonForm] = useState(false);
  const [showAddExamForm, setShowAddExamForm] = useState(false);
  const [showEditExamForm, setShowEditExamForm] = useState(false);
  const [showExamForm, setShowExamForm] = useState(false);
  const [examId, setExamId] = useState();

  const openNotification = () => {
    console.log("clicked");
    notification.info({
      message: "اختر الدورة اولا",
      icon: (
        <SmileOutlined
          style={{
            color: "#108ee9",
          }}
        />
      ),
    });
  };

  let renderCourses;
  useEffect(() => {
    if (localStorage.getItem("role") == "5") {
      const loadCourses = async () => {
        await dispatch(fetchCourses());
      };

      loadCourses();
    } else if (localStorage.getItem("role") == "4") {
      const loadCourses = async () => {
        await dispatch(fetchTeacherCourses());
      };

      loadCourses();
    }
  }, [dispatch]);
  useEffect(() => {
    if (localStorage.getItem("role") == "5") {
      const loadExams = async () => {
        await dispatch(fetchStudentExams());
      };

      loadExams();
    }
  }, [selectedCard]);

  if (localStorage.getItem("role") == "5" && courses_t) {
    renderCourses = courses_t?.data?.courses.map((course) => (
      <StudentCourseCard
        selectedCard={selectedCard}
        course={course}
        onClick={() => {
          setDescription(course.description);
          setSelectedCard(course.id);
          setCourseName(course.title);
        }}
      />
    ));
  } else if (localStorage.getItem("role") == "4" && Array.isArray(courses_t)) {
    renderCourses = courses_t?.map((course) => (
      <TeacherCourseCard
        selectedCard={selectedCard}
        course={course}
        onClick={() => {
          setDescription(course.description);
          setSelectedCard(course.id);
          setCourseName(course.title);
          setCourseDetails(course);
        }}
        setShowAddLiveSessionForm={(value) => setShowAddLiveSessionForm(value)}
        setShowAddExamForm={(value) => setShowAddExamForm(value)}
        setShowAddLessonForm={(value) => setShowAddLessonForm(value)}
      />
    ));
  }
  if (!courses_t) {
    return (
      <Home>
        <Result icon={<SmileOutlined />} title="لا يوجد دورات" />
      </Home>
    );
  }

  let renderExamsCard;
  if (exams && Array.isArray(exams)) {
    renderExamsCard = exams.map((exam) => {
      if (exam.course_id == selectedCard) {
        return (
          <ExamCard
            display={display}
            exam={exam}
            selectedCard={selectedCard}
            openNotification={openNotification}
            navigate={navigate}
          />
        );
      }
    });
  }

  return (
    <Home>
      <div className="containerExplore">
        {selectedVideo !== null &&
        showAddLessonForm == false &&
        showAddLiveSessionForm == false ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 6,
                margin: "0 5px",
              }}
            >
              <Typography.Title className="courses-title">
                {selectedVideo.video.title}
              </Typography.Title>
              <VideoPlayer
                id={selectedVideo}
                videoUrl={selectedVideo?.video?.url}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <Typography.Text className="under-image-title">
                  التفاصيل
                </Typography.Text>
                <Typography.Text>
                  {selectedVideo?.video.description}
                </Typography.Text>
              </div>
            </div>
          </>
        ) : showAddLessonForm == true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 6,
              margin: "0 5px",
            }}
          >
            <AddLessonForm
              id={courseDetails?.id}
              topicId={selectedVideo}
              setShowAddLessonForm={(value) => setShowAddLessonForm(value)}
            />
          </div>
        ) : showAddExamForm == true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 6,
              margin: "0 5px",
            }}
          >
            <AddExamForm
              id={courseDetails?.id}
              topicId={selectedVideo}
              setShowAddExamForm={(value) => setShowAddExamForm(value)}
            />
          </div>
        ) : showAddLiveSessionForm == true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 6,
              margin: "0 5px",
            }}
          >
            <AddLiveSessionForm
              id={courseDetails?.id}
              topicId={selectedVideo}
              setShowAddLiveSessionForm={(value) =>
                setShowAddLiveSessionForm(value)
              }
            />
          </div>
        ) : showExamForm == true ? (
          <ExamQuestionType
            id={examId}
            setShowExamForm={(value) => setShowExamForm(value)}
          />
        ) : showEditExamForm == true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 6,
              margin: "0 5px",
            }}
          >
            <EditExamForm
              id={examId}
              setShowEditExamForm={(value) => setShowEditExamForm(value)}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 6,
            }}
          >
            <PageTitle>دروسي</PageTitle>

            {status === "loading" || status === "idle" ? (
              <DefaultPageLoader />
            ) : courses_t?.length === 0 ? (
              <Result icon={<SmileOutlined />} title="لا يوجد دورات" />
            ) : (
              <div className="cards">{renderCourses}</div>
            )}
          </div>
        )}

        <div
          className={`${
            showAddLessonForm == true ||
            showAddExamForm == true ||
            showAddLiveSessionForm == true ||
            showExamForm == true ||
            showEditExamForm == true
              ? "disabled"
              : ""
          } leftSettings`}
        >
          {selectedCard && (
            <>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 20,
                  marginTop: 25,
                  paddingBottom: 20,
                  overflow: "auto",
                }}
              >
                <Space direction={"vertical"}>
                  <Space direction={"vertical"} size={"small"}>
                    {courseDetails?.image && (
                      <div className="cover">
                        <img
                          src={courseDetails.image}
                          width={"100%"}
                          height={200}
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    )}
                    <Typography className="left-title">التفاصيل</Typography>
                    <Typography className="course-description">
                      {description}
                    </Typography>
                  </Space>
                  <Typography className="left-title">
                    الدروس و الاختبارات
                  </Typography>
                </Space>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  overflowY: "scroll",
                }}
              >
                {renderExamsCard}
              </div>
              <TopicsList
                setShowAddExamForm={() => setShowAddExamForm(true)}
                setShowEditExamForm={(id) => {
                  setExamId(id)
                  setShowEditExamForm(true);
                }}
                setShowAddLessonForm={() => setShowAddLessonForm(true)}
                setShowExamForm={(id) => {
                  setExamId(id);
                  setShowExamForm(true);
                }}
                setShowAddLiveSessionForm={() =>
                  setShowAddLiveSessionForm(true)
                }
                onVideoClick={(videoUrl) => setSelectedVideo(videoUrl)}
                id={selectedCard}
                selectedVideo={selectedVideo}
              />
              {/* {role === "4" && (
                <>
                  <div
                    style={{
                      padding: 20,
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                      gap: 20,
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#2F3CED",
                        color: "#fff",
                        border: "1px solid #2F3CED",
                        borderRadius: 16,
                      }}
                      size="large"
                      onClick={() => {
                        // if (selectedCard === null) {
                        //   openNotification();
                        // } else {
                        //   navigate(`/Addlesson/${selectedCard}`);
                        // }
                        setShowAddLiveSessionForm(false);
                        setShowAddExamForm(false);
                        setShowAddLessonForm(true);
                      }}
                    >
                      اضافة درس
                    </Button>
                    <Button
                      size="large"
                      style={{
                        color: "#2F3CED",
                        backgroundColor: "#DDEDFF80",
                        border: "1px solid #2F3CED",
                        borderRadius: 16,

                        // boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
                      }}
                      onClick={() => {
                        // navigate(`/NewExam/${selectedCard}`);
                        setShowAddLiveSessionForm(false);
                        setShowAddLessonForm(false);
                        setShowAddExamForm(true);
                      }}
                    >
                      اضافة اختبار
                    </Button>

                    <Button
                      size="large"
                      style={{
                        color: "#2F3CED",
                        backgroundColor: "#DDEDFF80",
                        border: "1px solid #2F3CED",
                        borderRadius: 16,
                      }}
                      onClick={() => {
                        // if (selectedCard === null) {
                        //   openNotification();
                        // } else {
                        //   navigate(`/AddLiveLesson`);
                        // }
                        setShowAddLessonForm(false);
                        setShowAddExamForm(false);
                        setShowAddLiveSessionForm(true);
                      }}
                    >
                      اضافة بث مباشر مجدول
                    </Button>
                  </div>
                </>
              )} */}
            </>
          )}
        </div>
      </div>
    </Home>
  );
};
export default Courses;
